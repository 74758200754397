import React from 'react';

function Footer() {
  return (
       <div className='footer'>
         <div className='made-with-text'>
          <p>Made by</p>
          
          
 
          <a target="_blank" href="https://tharusha.dev" style={{paddingLeft:"10px"}}><b>Tharusha Jayasooriya</b></a>

         </div >
         
</div>       
       );
}

export default Footer;
