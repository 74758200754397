import React from 'react';
import './screenSizeChangeWarning.css'

function ScreenSizeChangeWarning() {
  return (
       <div className='screen-size-change-warning'>
         
          <span>Please refresh the page to make the canvas fit. (The current state of the world will be lost)</span>
          
          
 
         
</div>       
       );
}

export default ScreenSizeChangeWarning;
