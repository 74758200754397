import React, {useState} from "react";

const Header = () => {



  return (
  
  <header className= 'header'>
      <h1 id='main-heading'>Physics Simulator</h1>
      <p id="main-heading">Beta</p>
      <div className="header-profile-card" >
      </div>
 

   </header>

   
  
  )
};

export default Header;
